import React, { useState } from "react";
import { text } from "./svgText";
import { useSpring, animated } from "react-spring";

import styles from "./styles.module.css";

const AnimFeTurbulence = animated("feTurbulence");
const AnimFeDisplacementMap = animated("feDisplacementMap");

const Main = () => {
  const [open, toggle] = useState(false);
  const { freq, factor, scale, opacity } = useSpring({
    reverse: open,
    from: { factor: 10, opacity: 0, scale: 0.9, freq: "0.0175, 0.0" },
    to: { factor: 150, opacity: 1, scale: 1, freq: "0.0, 0.0" },
    config: { duration: 3000 },
  });

  return (
    <div>
      <div
        // style={{
        //   display: "flex",
        //   alignItems: "center",
        //   height: "100%",
        //   justifyContent: "center",
        // }}
        onClick={() => toggle(!open)}
      >
        <animated.svg
          className={styles.svg}
          style={{ scale, opacity }}
          viewBox="0 0 1278 1446"
        >
          <defs>
            <filter id="water">
              <AnimFeTurbulence
                type="fractalNoise"
                baseFrequency={freq}
                numOctaves="2"
                result="TURB"
                seed="8"
              />
              <AnimFeDisplacementMap
                xChannelSelector="R"
                yChannelSelector="G"
                in="SourceGraphic"
                in2="TURB"
                result="DISP"
                scale={factor}
              />
            </filter>
          </defs>
          <g filter="url(#water)">
            <animated.path d={text} fill="lightblue" />
          </g>
        </animated.svg>
      </div>
    </div>
  );
};

export default Main;
